.card-historique {
  margin-top: 10px;
}

.card-historique-container {
  padding: 10px;
  margin-left: 20px;
  background-color: #94b838e0;
  border-radius: 10px;
  color: white;
  width: 310px;
}

.steto {
  display: flex;
  margin-bottom: 10px;
}

.h3-steto {
  margin-left: 10px;
}
