.container-ordonnance {
  width: 700px;
  justify-content: center;
  align-items: center;
  border: solid 1px black;
  padding: 20px;
}

.title-ord {
  text-align: center;
}

.item-medecin-ord {
  margin-bottom: 6px;
  margin-left: 10px;
  margin-top: 10px;
}

.entete-patient-ord {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.input-ord {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.input-ord p {
  margin: 0;
  margin-right: 10px;
}

.input-ord input {
  border: none;
  background: transparent;
  background-color: rgba(241, 240, 240, 0.374);
  width: 150px;
  height: 30px;
}

.input-medicamment input {
  border: none;
  font-size: 20px;
  font-weight: 700;
  color: black;
}

.input-dosage input {
  font-size: 18px;
  border: none;
  color: black;
  height: 30px;
}

.dosage-qsp {
  display: flex;
  justify-content: space-around;
}

.btn-ord {
  background-color: white;
  border: 1px solid #024631;
  color: #024631;
  padding: 5px;
}

.btn-ord:hover {
  cursor: pointer;
  border: none;
  color: white;
  background-color: #93b838;
}

.barcode-container {
  margin-left: 400px;
}

.barcode {
  height: 50px;
  width: 350px;
}

.btns-downsend {
  display: flex;
}

.btn-down-send {
  margin-top: 20px;
  margin-left: 10px;
  background-color: #024631;
  color: white;
  border: none;
  padding: 10px;
}

.btn-down-send:hover {
  cursor: pointer;
  background-color: #93b838;
}
