.MuiTableRow-root > * {
  padding: 10px;
}

.MuiTableHead-root .MuiTableCell-root {
  font-weight: bold !important;
  font-style: normal !important;
}

.MuiTableCell-root {
  font-style: normal !important;
  font-size: 17px !important;
}

.btn-rej {
  background-color: #93b838;
  cursor: pointer;
  border-radius: 10px;
  padding: 8px;
  color: white;
  border: none;
}

.MuiTableCell-alignLeft {
  font-style: normal !important;
  font-size: 17px !important;
}

.list-rdv-medecins {
  display: flex;
  gap: 10px;
}

.list-rdv-nom {
  margin-top: 10px;
  margin-left: 10px;
  font-weight: 550 !important;
  font-size: 17px;
}

.Table {
  margin: 20px;
}

.Table td,
th {
  border: none !important;
}

.Table:first-child {
  border-radius: 0.7rem !important;
}

.status {
  padding: 8px;
  border-radius: 9px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
}

.filters {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  margin: 1rem 0rem;
  padding: 1rem;
}

.span-tableMed {
  color: black !important;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  margin-left: 10px;
  padding-top: 50px;
}
