@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500&display=swap");

:root {
  --green: #93b838;
  --greenf: #024631;
  --white: #ffffff;
  --shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  font-family: "Montserrat", sans-serif;
}

.search-container {
  display: flex;
  align-items: center;
  min-width: 700px;
  background-color: var(--white);
  border-radius: 50px;
  margin-left: 50px;
}

.dropdown {
  position: relative;
  width: 280px;
  border-radius: 50px;
  border: 1px solid var(--white);
  background-color: var(--green);
  box-shadow: var(--shadow);
  cursor: pointer;
}

.dropdown-text {
  display: flex;
  text-align: center;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 500;
  color: var(--white);
  padding: 1rem 1.5rem;
}

.tout {
  font-size: 1.2rem;
  font-family: "Montserrat";
  color: white;
}

.dropdown-list {
  position: absolute;
  top: 4rem;
  width: 100%;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: var(--shadow);
  color: #024631;
  font-weight: 500;
  max-height: 0;
  overflow: hidden;
  background-color: var(--white);
  transition: max-height 0.5s;
}

#list.show {
  max-height: 310px;
}

.dropdown-list-item {
  font-size: 1.1rem;
  font-weight: 500;
  padding: 0rem 0rem 0.2rem 0.2rem;
  cursor: pointer;
  margin-left: 20px;
  transition: margin-left 0.2s ease, color 0.2s ease, font-size 0.2s ease;
}

.dropdown-list-item:hover {
  margin-left: 1rem;
  color: var(--green);
  font-size: 1.2rem;
}

.search-input {
  width: 30%;
  margin-left: 150px;
  box-sizing: border-box;
}

.search-box {
  display: flex;
  align-items: center;
  color: var(--greenf);
  margin-left: 66px;
  font-size: x-large;
  cursor: pointer;
}

.search-box input {
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  color: var(--greenf);
  border: 0;
  outline: 0;
}

.search-box i {
  font-size: 1.3rem;
  cursor: pointer;
}

.search-box input::placeholder {
  font-size: 1rem;
  font-weight: 500;
  color: var(--greenf);
}
