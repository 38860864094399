/* General container for the observation view */
.observation-medical-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 800px; /* Optionally limit the maximum width of the container */
  margin: auto; /* Center the container */
}

/* Title for the notes section */
.observation-title {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
}

/* Styling for each observation item */
.observation-item {
  font-weight: bold;
  margin-top: 15px;
  color: #555;
}

/* Styling for textareas */
.txt-size,
.txt-size-interro,
.txt-size-ant,
.txt-size-conclusion {
  width: calc(100% - 20px); /* Adjust width to fit container with padding */
  height: 80px;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  resize: none;
  font-size: 0.9rem;
  color: #333;
}

/* Specific text area sizes */
.txt-size {
  height: 100px; /* Adjust height if needed */
}

.txt-size-interro {
  height: 120px; /* Adjust height if needed */
}

.txt-size-ant {
  height: 80px; /* Adjust height if needed */
}

.txt-size-conclusion {
  height: 80px; /* Adjust height if needed */
}

/* Styling for input elements */
.input-size {
  width: 125px; /* Adjust width for a more compact layout */
  padding: 8px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 0.9rem;
  color: #333;
}
