.messagerie {
  background-color: rgba(181, 232, 238, 0.544);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.chat-sidebar {
  flex: 0.65;
  background-color: #024631;
  color: white;
}

.height-profil {
  height: 100vh;
  width: 250px;
  background-color: white;
}
