.container {
  text-align: center;
  padding: 20px;
}

.links-container {
  margin: 20px 0;
}

.links-container a {
  display: block;
  padding: 15px 20px;
  margin: 10px 0;
  text-decoration: none;
  color: #fff;
  background-color: #024631;
  border: none;
  border-radius: 5px;
  font-size: 1em;
}

.links-container a:hover {
  background-color: #93b838;
}

.icon {
  font-size: 3em;
  margin: 10px;
  color: #93b838;
}

.confirmation-container {
  background-color: #f9f9f9;
  padding: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 35px;
}

.confirmation-field {
  font-size: 20px;

  margin-bottom: 20px;
  margin-left: 400px;
  display: flex;
  align-items: center;
}

.confirmation-field strong {
  margin-right: 10px;
  min-width: 150px;
  font-size: 20px;
  display: inline-block;
  text-align: right;
}

.confirmation-field p {
  margin: 0;
}
