.set-portal-grid-historique {
  display: grid;
  grid-template-columns: 0.7fr 3.3fr;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: white;
}

.main-hist {
  display: grid;
  grid-template-columns: 1fr 2fr 3fr;
  gap: 40px;
}

.hist-text {
  margin-left: 20px;
  margin-top: 40px;
}

.observation-middle {
  margin-top: 50px;
  height: 500px;
}

.result-title {
  margin-top: 50px;
  margin-left: 9px;
}
