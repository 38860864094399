.card {
  border: solid rgba(0, 0, 0, 0.101) 1px;
  background-color: rgba(255, 255, 255, 0.89);
  padding: 20px;
  width: 900px;
  margin-left: 50px;
}

.card.selected {
  background-color: rgba(230, 247, 224, 0.701);
}

.card__image {
  width: 80px;
  height: 80px;
  border-radius: 200px;
  border: solid rgba(0, 0, 0, 0.216) 1px;
}

.top-card {
  display: flex;
}

.card-btn {
  margin-right: 100px;
  width: 250px;
  background-color: #024631;
  text-decoration: none;
  border-radius: 5px;
  height: 40px;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 11px;
  font-size: 15px;
}

.card-btn:hover {
  background-color: #93b838;
  cursor: pointer;
  border: none;
}

.adresse-card {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: rgba(18, 1, 1, 0.574);
  padding-top: 10px;
  padding-bottom: 30px;
}

.card-role {
  font-size: larger;
  padding-left: 40px;
  padding-top: 50px;
}

.price {
  justify-content: flex-end;
  text-align: right;
  font-size: 20px;
}

del {
  color: red;
}

.spec {
  font-size: 20px;
  padding-top: 10px;
  padding-left: 20px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.704);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 500px;
  width: 80%;
}

.pop-header {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  padding-bottom: 37px;
}

.popup-content h2,
.popup-content h3 {
  margin: 10px 0;
  font-weight: 400;
}

.closeIcon {
  top: 10px;
  float: right;
  cursor: pointer;
}

.pop-btn {
  padding: 10px;
  background-color: #024631;
  width: 120px;
  height: 40px;
  border: none;
  border-radius: 5px;
}

.pop-link {
  text-decoration: none;
  color: white;
}

.rating-stars {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 20px;
}

.rating-stars svg {
  color: rgb(243, 207, 3);
  margin-right: 2px;
}
