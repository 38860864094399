.background-available {
  background-color: white;
  background-repeat: no-repeat;
  background-size: 1950px 900px;
  overflow-y: auto;
}

.availability-text {
  font-style: bold;
  color: #024631;
  padding: 30px;
}

hr {
  width: 100%;
  margin-bottom: 20px;
  color: gray;
}

.day-list {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 25px;
  padding: 10px;
}

.day-item {
  font-family: "Montserrat", sans-serif;
  font-size: larger;
  font-weight: 500;
}
.custom-grid {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  padding: 10px;
}

@media (min-width: 768px) {
  .custom-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.slot-add {
  padding: 20px;
  margin-left: 0px;
}
.time {
  margin-top: 3px;
  display: flex;
  gap: 1rem;
  margin-left: 10px;
}

.btn-av {
  padding-top: 30px;
  margin-left: 15px;
  cursor: pointer;
  justify-content: space-evenly;
}

.delete-btn {
  background-color: rgba(247, 9, 9, 0.775);
  color: white;
  border: none;
  border-radius: 5px;
  height: 50px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.update-btn {
  background-color: #93b838;
  color: white;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  height: 50px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.btn-delete {
  padding-top: 10px;
}

.add-btn {
  background-color: #1976d2b0;
  color: white;
  border: none;
  border-radius: 5px;
  height: 40px;
  width: 100px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.valider-btn {
  background-color: #024631;
  color: white;
  border: none;
  border-radius: 5px;
  height: 50px;
  width: 100px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.valider-btn:hover {
  background-color: #93b838;
  border: none;
}

.mettre-a-jour-av {
  cursor: pointer;
  margin-left: 77rem;
}

.mettre-a-jour-av:hover {
  background-color: #93b838;

  border: none;
}

.intro-dr-av {
  font-size: 20px;
  margin-left: 30px;
  margin-bottom: 10px;
}

.intro-av {
  margin-left: 30px;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
  margin-right: 20px;
}

.interval-av {
  display: flex;
  justify-content: flex-start;
  justify-items: center;
}

.date-picker-interval {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: solid rgba(128, 128, 128, 0.655) 1px;
  border-radius: 10px;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
  padding-right: 0;
  overflow: hidden;
}
