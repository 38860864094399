.cloud-button {
  display: flex;
  flex-direction: column;
  color: rgb(51, 49, 49) !important;
  background-color: white !important;
  width: 700px !important;
  height: 300px !important;
}

.onlyMe {
  color: rgba(75, 75, 75, 0.796) !important;
  box-shadow: none !important;
  width: 100px !important;
  height: 100px !important;
  margin-left: 40px !important;
}
