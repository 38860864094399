.service {
  padding-left: 50px;
}

.icon-service {
  width: 270px;
  height: 300px;
  padding: 30px;
  transform: translateY(1%);
  transition: opacity 2s ease, transform 1s ease;
}

.icon-service.active {
  opacity: 1;
  transform: translateY(-60%);
}

.text-service {
  color: #024631;
  font-size: 21px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 35px;
  padding-bottom: 30px;
  padding-top: 20px;
}

.icon-service:hover,
.text-service:hover {
  transform: scale(1.5);
}
