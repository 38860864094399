.button {
  background-color: #024631;
  text-decoration: none;
  border-radius: 5px;
  height: 60px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 11px;
  font-size: 20px;
}

.slot-add-btn {
  background-color: #1976d2;
  text-decoration: none;
  border-radius: 5px;
  height: 30px;
  width: 80px;
  font-size: 12px;
  align-items: center;
  border: none;
  cursor: pointer;
}

.patient-btn {
  background-color: #93b838;
  color: white;
  margin-left: 10px;
  padding-top: 10px;
  padding-bottom: 11px;
  font-size: 20px;
}

.patient-btn :hover {
  background-color: rgba(31, 73, 80, 0.8);
}

.patient-link-btn {
  background-color: #93b838;
  height: 48px;
  color: white;
  margin-left: 10px;
  margin-top: -10px;
  padding-top: 10px;
  padding-bottom: 11px;
  font-size: 20px;
  border: none;
}

.profile-btn {
  margin-left: 26px;
}
.profile-btn:hover {
  background-color: #93b838;
}

.btn-med {
  margin-left: 50px;
  padding: 18px 25px 18px 25px;
  font-size: x-large;
  border-radius: 50px;
  background-color: #078c;
}

.btn-med:hover {
  background-color: #93b838;
}
