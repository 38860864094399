.find-doctor-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.search-map {
  width: 900px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.find-doctor-right {
  width: 750px;
}

.doctor-map {
  display: flex;
}

.nav-find-dr {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 60px);
}

.map-side {
  flex: 1;
  margin-top: 20px;
  margin-left: 10px;
  height: 100%;
}

.leaflet-container {
  height: 100vh;
}
