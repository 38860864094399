.mr {
  padding-right: 130px;
}

.icons-fetch {
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
}

.mode-icon {
  display: flex;
  flex-direction: column;
  border: solid 1px black;
  border-radius: 10px;
  padding: 20px;
  width: 150px;
  height: 130px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.mode-icon:hover {
  background-color: rgba(183, 241, 201, 0.436);
  cursor: pointer;
  border: solid 2px #93b838;
}

h2.icon-title {
  padding: 10px;
  margin-top: 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}
