.menuvisio-list {
  display: flex;
  padding: 20px;
  gap: 60px;
}

.menuvisio-list-item {
  text-decoration: none;
  font-size: 20px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: rgb(79, 77, 77);
}

.menuvisio-list-item:hover {
  color: black;
  cursor: pointer;
}

.active-link {
  color: #6c872e;
  font-size: 20px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  list-style-type: none;
  text-decoration: none;
}

.active-link::after {
  content: "";
  display: block;
  width: 100%;
  margin-top: 26px;
  height: 7px;
  background-color: #6c872e;
}
