.round-button {
  background-color: #a3ca46;
  color: whitesmoke;
  border: whitesmoke;
  border-radius: 200px;
  width: 45px;
  height: 45px;
  margin-right: 10px;
}

.top {
  display: flex;
}

.section-title {
  color: #024631;
  margin-left: 60px;
  font-size: 35px;
}

.btn-group {
  margin-right: 20px;
  padding-top: 50px;
  margin: 10px;
  padding-left: 1340px;
}

.icon-feed {
  width: 50px;
  margin-left: 200px;
}

.content-feed {
  position: absolute;
  font-size: 28px;
  margin-top: 70px;
  width: 50%;
  margin-left: 200px;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 2s ease, transform 1s ease;
}

.content-feed.active {
  opacity: 1;
  transform: translateX(0);
}
.image-feed {
  width: 550px;
  height: 500px;
  margin-left: 1200px;
  opacity: 0;
  transition: opacity 4s ease;
}

.image-feed.active {
  opacity: 1;
}

.author {
  position: absolute;
  left: 51%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  font-weight: 600;
  color: #024631;
  margin-top: -200px;
}

.video-feed {
  width: 100%;
  max-width: 1100px;
  height: auto;
  border: 5px solid rgb(255, 255, 255);
  border-radius: 10px;
  margin: 20px 0 20px auto;
  margin-right: 30px;
  display: block;
  margin-bottom: 40px;
}
