.message {
  display: flex;
  gap: 20px;
}

.messages {
  width: 100%;
  opacity: 0.8;
  background-image: url("../../assets/images/background-chat.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 10px;
}

.message.owner {
  flex-direction: row-reverse;
}

.message.owner .dm-text {
  background-color: #024631;
  color: white;
  border-radius: 10px 0px 10px 10px;
  max-width: max-content;
}

.messageInfo {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.messageInfo .img-dm {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.messageContent {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.messageContent .img-dm-send {
  width: 50%;
  height: 140px;
}

.dm-text {
  background-color: white;
  padding: 10px 20px;
  border-radius: 0px 10px 10px 10px;
}

.maintenant {
  color: gray;
  font-family: "Montserrat", sans-serif;
  font-size: small;
  margin-top: 13px;
}
