.rating-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.stars {
  margin-top: 30px;
  margin-bottom: 30px;
}

.star {
  font-size: 3rem;
  cursor: pointer;
  color: gray;
}

.star.filled {
  color: gold;
}

.rate-submit {
  background-color: #033205;
  color: white;
  padding: 10px 20px;
  font-size: 15px;
  border-radius: 5px;
  border: none;
  margin-bottom: 20px;
}

.rate-submit:hover {
  background-color: #179b1e;
}

button {
  margin-top: 10px;
  padding: 5px 10px;
  cursor: pointer;
}

.close-modal {
  display: flex;
  gap: 20px;
}

.close-rate {
  background-color: rgb(250, 234, 214);
  cursor: pointer;
}
