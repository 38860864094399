.txt-size,
.txt-size-interro,
.txt-size-ant,
.txt-size-conclusion,
.input-size {
  padding: 10px;
  box-sizing: border-box;
}

.observation-title {
  font-size: 1.5rem;
  color: rgb(30, 29, 29);
}

.hsitorique-title {
  font-size: 1.5rem;
  color: rgb(30, 29, 29);
  margin-left: 20px;
  margin-top: 50px;
}

.observation-item {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.txt-size {
  width: 600px;
  height: 60px;
  font-size: 1rem;
}

.input-size {
  width: 142px;
  height: 40px;
  font-size: 14px;
}

.txt-size-interro {
  width: 600px;
  height: 150px;
  font-size: 1rem;
}

textarea {
  border-radius: 5px;
}

.txt-size-ant {
  width: 600px;
  height: 100px;
  font-size: 1rem;
}

.obser-inputs {
  display: flex;
  gap: 10px;
}

.txt-size-conclusion {
  width: 600px;
  height: 80px;
  font-size: 1rem;
}

.btn-obs-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn-observation {
  padding: 10px;
  color: white;
  background-color: #024631;
  border-radius: 10px;
  font-size: large;
  cursor: pointer;
  border: none;
}

.btn-observation:hover {
  background-color: #93b838;
}
