.profile-container {
  padding: 20px;
  border: 1px solid gray;
  border-radius: 10px;
  margin-left: 40px;
}

.main-profil-medecin {
  padding: 20px;
}

.set-portal-grid-patient {
  display: grid;
  grid-template-columns: 0.7fr 3.8fr;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: white;
}

.profil-img-name {
  display: flex;
}

.del-edit-btns {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.profil-title {
  padding-top: 30px;
  margin-left: 20px;
}

.input-profil {
  padding: 10px;
  display: flex;
  margin-bottom: 5px;
  font-size: 1rem;
  border: none;
  background-color: rgb(246, 245, 245);
}

.password-profile {
  display: flex;
  flex-direction: column !important;
  width: 400px;
  gap: 10px;
  border: none;
}

.personnal-information {
  gap: 10px;
}

textarea {
  width: 400px;
  height: 100px;
  font-size: 1rem;
  border: none;
  background-color: rgb(246, 245, 245);
}

.info-pro-item {
  margin: 5px;
  font-size: 1.1rem;
}

.profile-image-container img {
  width: 150px;
  height: 150px;
  border-radius: 10px;
}

.info-pro-item {
  padding: 5px;
  margin-right: 30px;
}

.btn-modan {
  background-color: #024631;
  color: white;
  margin-left: 10px;
  padding: 9px;
  border-radius: 5px;
  border: none;
}

.image-btn-up {
  margin-top: 30px;
  margin-left: -20px;
}

.btn-modan-img {
  margin-top: 30px;
}
