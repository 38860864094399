body {
  background: linear-gradient(
    to left,
    rgba(2, 70, 49, 0.8),
    rgba(177, 225, 64, 0.099)
  );
  margin: 0;
  padding: 0;
}

.white {
  padding-top: 30px;
  margin-right: 30px;
}
.dark {
  background-color: rgb(255, 255, 255) !important;
}

.dark p {
  color: #a3ca46;
  font-weight: bold;
}

.visioconf-resize {
  height: 700px !important;
}

.main-section {
  display: flex;
  justify-content: space-between;
  padding-top: 150px;
}

.home-left {
  margin-top: -44px;
  margin-left: -50px;
  animation: slideInLeft 2s ease-in-out;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.home-txt {
  color: #024631;
  font-size: 34px;
  font-family: "Montserrat", sans-serif;
  margin-left: 50px;
  margin-top: 100px;
  font-weight: 600;
}

.home-subtxt {
  color: #6c872e;
  font-size: 35px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  margin-left: 50px;
  margin-top: 10px;
  margin-bottom: 50px;
}

.home-txt:hover,
.home-img:hover {
  transform: scale(1);
}

.home-right {
  animation: slideInFromTop 2s ease-in-out forwards;
  margin-top: -150px;
}

@keyframes slideInFromTop {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.home-img {
  width: 897px;
  height: 680px;
  padding-top: 120px;
  margin-right: -20px;
  margin-bottom: 265px;
}
.main-section {
  margin-bottom: -170px;
}

.about-section {
  margin-top: 20px;
  margin-bottom: 40px;
}

.home-searchbar {
  width: 53%;
  font-size: 1.2rem;
  font-style: initial;
  margin-left: 20px;
  border: 0;
  outline: 0;
  font-weight: 500;
}

.about-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 30px;
}

.about-img {
  margin-left: 20px;
  border-bottom-left-radius: 100px;
  border-top-right-radius: 100px;
  padding-top: 30px;
  width: 700px;
  height: 500px;
  transform: translateY(50%);
  transition: opacity 2s ease, transform 1s ease;
}

.about-img.active {
  opacity: 1;
  transform: translateY(-60%);
}

.about-content {
  padding-left: 60px;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 2s ease, transform 1s ease;
  color: #024631;
  padding-bottom: 20px;
}

.about-content.active {
  opacity: 1;
  transform: translateX(0);
}

.about-txt {
  font-size: 50px;
  font-weight: 700;
  color: #fcfcfc;
  font-family: "Times New Roman", Times, serif;
}

.about-subtxt {
  padding-top: 20px;
  font-size: 30px;
  color: rgba(0, 0, 0, 0.868);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  padding-bottom: 50px;
}

.service-section {
  padding-top: 1px;
}

.service-txt {
  color: #024631;
  padding-top: 0px;
  font-size: 30px;
  margin-left: 60px;
  padding-bottom: 120px;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 2s ease, transform 1s ease;
}

.service-txt.active {
  opacity: 1;
  transform: translateX(0);
}
span {
  color: #4fca46;
  font-size: x-large;
  font-family: Georgia, "Times New Roman", Times, serif;
}

span {
  color: #4fca46;
  font-size: x-large;
}

.white {
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
}

.feedback-section {
  font-size: 80px;
}
