.container {
  width: fit-content;
  background-color: white;
  padding-top: 2rem;
  padding-bottom: 1rem;
  padding-left: 6rem;
  padding-right: 6rem;
  border-radius: 1rem;
}

.smallImage {
  max-width: 200px;
  max-height: 150px;
  border: 1px solid #ccc;
  object-fit: cover;
  cursor: pointer;
}

.imageLabel {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}
.labelText {
  color: rgb(61, 60, 60);
  margin-top: 15px;
}
.imagePreview {
  cursor: pointer;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  padding: 2rem 0rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.footer {
  display: flex;
  justify-content: center;
  column-gap: 1rem;
}

.inputContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.iconContainer {
  position: absolute;
  transform: translateX(0.8rem);
}

.input {
  width: 100%;
  padding: 0.8rem 0.5rem;
  padding-left: 2.5rem;
  border-style: solid;
  border-color: hsl(0, 0%, 80%);
  border-width: 1px;
}

.passwordInput {
  width: 100%;
  padding: 0.8rem 0.5rem;
  padding-left: 2.5rem;
  border-style: solid;
  border-color: hsl(0, 0%, 80%);
  border-width: 1px;
  border-right: none;
}

.row {
  display: flex;
  column-gap: 1rem;
}

.buttonSubmit {
  padding: 1rem 1.5rem;
  background-color: #024631;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.buttonSubmit:hover {
  background-color: #93b838;
}

.buttonCancel {
  padding: 1rem 1.5rem;
  background-color: gray;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.linkInput {
  display: flex;
  justify-content: center;
}

.passwordEyeIcon {
  border-style: solid;
  border-color: hsl(0, 0%, 80%);
  border-width: 1px;
  border-left: none;
  padding: 0.8rem;
}

.dateInput {
  display: flex;
  column-gap: 0.5rem;
}
