.rdv-grid {
  display: grid;
  grid-template-columns: 0.7fr 3fr;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: white;
}

.left-grid {
  background-color: #024732;
  gap: 2rem;
}

.logo-rdv {
  width: 340px;
  justify-content: center;
  padding: 20px;
}

.text-rdv {
  color: white;
  font-family: "montserrat", sans-serif;
  font-size: 1.5rem;
  margin-left: 25px;
}

.detail-doctor {
  padding-top: 50px;
}
