.userChatImg {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 19px;
  cursor: pointer;
  &:hover {
    background-color: #94b83863;
  }
}

.userChatInfo span {
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-size: large;
  font-weight: 500;
}

.user-img-chat {
  background-color: white;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.searchForm {
  padding: 10px;
}

.searchForm-input {
  background-color: transparent;
  padding-bottom: 10px;
  border: none;
  font-size: medium;
  color: white;
  outline: none;
  &::placeholder {
    color: lightgray;
  }
}

.msg-content {
  color: white;
}

.searchUser {
  display: flex;
  padding: 10px;
  border-bottom: #94b83863 solid 1px;
}

.searchUser:hover {
  background-color: rgba(127, 255, 212, 0.299);
}

.userChatInfo {
  margin-left: 10px;
  display: flex;
  gap: 15px;
}
