.patient-fullname {
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 50px;
}

.btn-patient {
  background-color: #024631;
  padding: 10px;
  color: white;
  border-radius: 5px;
  border: none;
  margin-top: 10px;
  cursor: pointer;
}

.btn-patient:hover {
  background-color: #93b838;
}

.image-patient {
  margin-left: 50px;
  width: 150px;
  height: 150px;
  border-radius: 100%;
}
