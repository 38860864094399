.signin-pat {
  background-image: url("../../assets/images/signin-pat.png");
  background-repeat: no-repeat;
  background-size: 1950px 900px;
}

.legend {
  margin-bottom: 30px;
  font-size: 25px;
  margin-left: 70px;
}

.form-signin-med {
  background-color: white;
  border-radius: 50px;
  width: 37%;
  margin-top: 170px;
  margin-left: 150px;
}

.cnx-hover {
  background-color: #93b838;
}

.auth {
  padding: 30px 150px 50px;
}

.btn-design {
  margin-left: 150px;
}
.btn-auth {
  padding-left: 15px;
}

.btn-auth:hover {
  background-color: rgba(89, 164, 42, 0.866);
  border: none;
  cursor: pointer;
}
