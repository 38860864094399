.profile-rating {
  justify-content: space-around;
  padding-top: 120px;
  background-color: white;
  /* border-left: solid 0.1rem rgba(138, 210, 31, 0.856); */
  height: 100vh;
  background-color: rgba(192, 233, 254, 0.146);
  text-align: center; /* Centre le texte */
}

#profilePic {
  border-radius: 300px;
  width: 200px;
  height: 200px;
}

.profile-name {
  color: #024732;
  font-size: larger;
  font-family: Arial, Helvetica, sans-serif;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.profile-role {
  font-size: larger;
  padding-left: 10px;
  padding-top: 8px;
}

.rating {
  margin-left: 41px;
}

.rating span {
  color: gold;
  font-size: 35px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.description {
  margin-left: 25px;
  padding-top: 40px;
  padding-bottom: 50px;
  font-size: larger;
  color: rgba(0, 0, 0, 0.756);
}
