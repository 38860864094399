.logo-chat {
  padding-top: 10px;
  width: 200px;
}

.chat-navbar {
  display: flex;
  align-items: center;
  background-color: rgba(2, 49, 2, 0.737);
  justify-content: space-between;
}

.navbar-chat-nom {
  display: flex;
  gap: 7px;
  padding-top: 7px;
}

.deta {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.user {
  display: flex;
  padding: 17px;
  padding-top: 27px;
  gap: 10px;
}

.user-img {
  background-color: white;
  height: 38px;
  width: 38px;
  object-fit: cover;
}

.chat-logout {
  background-color: #93b838;
  color: #ffffff;
  font-size: 10px;
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.chat-logout button {
  padding-top: 6rem;
}
