.MainDash {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: white;
}

.txt-portal {
  color: #024631;
  margin-left: 20px;
  margin-top: 60px;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
}
