.footer {
  background-color: #024631;
  padding: 20px 0;
}

.footer-container {
  max-width: 1200px;
  height: 100px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-logo {
  flex: 1;
}

.logo {
  width: 200px;
  margin-top: 30px;
  margin-left: -150px;
}

.footer-social {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-left: -350px;
}

.social-icon {
  font-size: 35px;
  margin-left: 20px;
  color: #eae6e6;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #007bff;
}

.footer-info {
  flex: 1;
  text-align: right;
}

.footer-info p {
  margin: 5px 0;
  font-size: 14px;
  color: #888;
}
