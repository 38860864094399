.set-portal-grid {
  display: grid;
  grid-template-columns: 0.7fr 3fr 0.8fr;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: white;
}

.portal {
  background-color: white;
  background-repeat: no-repeat;
  background-size: 1950px 900px;
}

.home-left {
  padding: 50px;
}

.home-txt-med {
  margin-left: 50px;
  margin-top: 100px;
  font-size: 50px;
  font-family: "Montserrat", sans-serif;
}
.home-img-med {
  width: 481px;
  height: 700px;
  margin-top: 20px;
  margin-right: 30px;
}
.care {
  color: #024631;
  font-size: 50px;
  font-family: "Montserrat", sans-serif;
}

.line {
  color: #a0df0f;
  font-size: 50px;
  font-family: "Montserrat", sans-serif;
}

.home-subtxt-med {
  font-size: 25px;
  color: rgba(0, 0, 0, 0.772);
  margin-left: 50px;
  padding-top: 30px;
  padding-bottom: 50px;
  font-family: "Monserrat", sans-serif "Lucida Sans", Arial, sans-serif;
}

.about-content-med {
  padding-left: 60px;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 2s ease, transform 1s ease;
  color: #024631;
}

.about-content-med.active {
  opacity: 1;
  transform: translateX(0);
}

.about-txt-med {
  font-size: 50px;
  padding-top: 50px;
  font-weight: 700;
  color: #fcfcfc;
  font-family: "Times New Roman", Times, serif;
}

.about-subtxt-med {
  padding-top: 20px;
  font-size: 30px;
  color: rgba(0, 0, 0, 0.868);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.about-img-med {
  margin-left: 20px;
  border-bottom-left-radius: 100px;
  border-top-right-radius: 100px;
  padding-top: 30px;
  width: 700px;
  height: 500px;
  transform: translateX(50%);
  transition: opacity 2s ease, transform 1s ease;
}

.about-img-med.active {
  opacity: 1;
  transform: translateY(0%);
}

.section-title-med {
  margin-top: 150px;
  color: #024631;
  margin-left: 60px;
  font-size: 35px;
}

.patient-profil {
  padding: 20px;
}
