.rdvCard {
  border: solid rgba(0, 0, 0, 0.101) 1px;
  background-color: rgba(255, 255, 255, 0.89);
  padding: 10px;
  width: 320px;
  margin-left: 20px;
  border-radius: 20px;
}

.card__image {
  width: 80px;
  height: 80px;
  border-radius: 200px;
  border: solid rgba(71, 69, 69, 0.216) 1px;
}

.top-card {
  display: flex;
}

.card-btn {
  margin-right: 100px;
  width: 300px;
}

.adresse-card {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: rgba(18, 1, 1, 0.574);
  padding-top: 10px;
  padding-bottom: 30px;
}

.card-role {
  font-size: larger;
  padding-left: 40px;
  padding-top: 50px;
}

.price {
  justify-content: flex-end;
  text-align: right;
  font-size: 20px;
}

del {
  color: red;
}

.spec {
  font-size: 20px;
  padding-top: 10px;
  padding-left: 20px;
}
