.signin-page {
  background-image: url("../../assets/images/background2.jpg");
  background-repeat: no-repeat;
  background-size: 1950px 900px;
}

.auth-form-container {
  padding-top: 12rem;
  margin-left: 200px;
}

.legend {
  margin-bottom: 30px;
  font-size: 25px;
  margin-left: 70px;
}

.form-signin-med {
  background-color: white;
  border-radius: 50px;
  width: 37%;
  margin-top: 170px;
  margin-left: 150px;
}

.auth {
  padding: 30px 150px 50px;
}

.btn-design {
  margin-left: 150px;
}

.password-input-container {
  display: flex;
  justify-content: space-between;
}
