.meeting-schedule {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.time-date-patient {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.time-date-patient > div {
  flex: 1;
}

.calendar {
  border: black 2px solid;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  width: 100%;
}

.date-patient {
  border: 1px solid #024631;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: white;
  text-decoration: none;
  border-radius: 5px;
  height: 40px;
  width: 120px;
  color: #024631;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 11px;
  font-size: 15px;
}

.date-patient.active {
  background-color: #024631;
  color: white;
}

.date-patient:hover {
  background-color: #024631;
  color: white;
}
