.signup-page {
  background-image: url("../../assets/images/background.jpg");
  background-repeat: no-repeat;
  background-size: 1950px 900px;
  background-color: rgba(255, 255, 255, 0.2);
}

.form {
  margin-left: 70rem;
  padding-top: 30px;
}

.logo-form {
  width: 250px;
}

.fullname {
  gap: 15px;
}

.prenom input {
  margin-right: 20px;
}

.sign-med {
  margin-left: 150px;
}
