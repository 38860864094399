.search-pat {
  padding-top: 40px;
}

.title-liste-pat {
  margin-left: 45px;
  margin-top: 60px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600 !important;
  font-size: 25px;
  color: rgb(3, 63, 3);
}

.title-liste-profil {
  margin-left: 45px;
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600 !important;
  font-size: 25px;
  color: rgb(3, 63, 3);
}

.table-patients {
  margin-left: 50px;
}
