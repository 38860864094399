.motif-title {
  margin-top: 60px;
  color: #024631;
  font-size: 30px;
  margin-bottom: 20px;
}

.motif-label {
  margin-bottom: 50px;
  font-family: "montserrat", sans-serif;
  font-size: 20px;
}

label.motif-label {
  padding-bottom: 25px;
}
