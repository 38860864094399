.sidebar {
  background-color: #024732;
  border-top-right-radius: 20px;
  height: 100vh;
  width: 370px;
}

.logo-portal {
  padding-top: 20px;
  width: 300px;
  padding-left: 40px;
  padding-bottom: 20px;
}

.menuItemLink {
  text-decoration: none;
  color: white;
}

.menuItemLink:hover {
  text-decoration: none;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 2rem;
  text-decoration: none;
  list-style-type: none;
  margin-left: 2rem;
  margin-top: 12px;
  position: relative;
  transition: all 300ms ease;
  font-size: 10px;
  border-radius: 0.7rem;
}

.menuItem:hover {
  cursor: pointer;
}

.menuItem.active {
  background-color: #93b838;
  margin-left: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 0.7;
}

.item {
  height: 1.5rem;
  justify-items: center;
}

span {
  color: white;
}

li {
  margin-top: 20px;
  margin-bottom: 18px;
  text-decoration: none;
  list-style-type: none;
  outline: none;
}

.icon-sidebar {
  margin-right: 10px;
  padding: 10px;
  color: white;
}

.text {
  font-family: "Montserrat";
  font-size: 22px;
}
