.no-chat-grid {
  display: grid;
  grid-template-columns: 0.3fr 15fr 2fr;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: -150px;
}

.chat-flex img {
  margin-left: 460px;
}

.noChat-text {
  font-size: 22px;
  color: rgb(17, 58, 163);
  margin-left: 430px;
  font-weight: 600;
  width: 600px;
  justify-items: center;
  margin-top: 20px;
}
