.chat {
  flex: 2;
  background-color: #f4f8f7;
  background-repeat: no-repeat;
  background-size: 1950px 900px;
}

.chat-span-header {
  color: #024631;
  font-weight: 700;
}

.chatInfo {
  border-top-right-radius: 20px;
  height: 80px;
  padding: 30px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.6);
}

.input-chat {
  height: 80px;
  background-color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-chat input {
  width: 100%;
  border: none;
  font-size: 18px;
  outline: none;
  &::placeholder {
    color: lightgray;
  }
}

.messages {
  padding: 10px;
  height: calc(100% - 160px);
  /* 160= 80 (chatInfo) + 80 (Input) */
  overflow-y: scroll;
  overflow-x: hidden;
}

.send {
  display: flex;
  align-items: center;
  gap: 10px;
}

.send-btn {
  background-color: #024631;
  color: white;
  padding: 14px;
  font-weight: 500;
  border-radius: 5px;
}

.img-send {
  cursor: pointer;
  height: 35px;
}
