* {
  margin: 0;
  padding: 0;
}
.navbar-container {
  display: flex;
  justify-items: center;
  justify-content: space-between;
  background-color: white;
  height: 126px;
  position: relative;
}

#navbar__logo {
  width: 300px;
  padding: 20px;
  margin-top: 8px;
}

.nav-item ul {
  list-style-type: none;
  text-decoration: none;
  display: flex;
  padding-top: 30px;
  padding-left: 100px;
  gap: 50px;
}

.link {
  justify-content: center;
  color: black;
  margin-left: 20px;
  list-style-type: none;
  text-decoration: none;
  font-size: 25px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
}

.link:hover {
  color: #6c872e;
}

.active-link {
  color: #6c872e;
  font-size: 25px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  list-style-type: none;
  text-decoration: none;
}

.active-link::after {
  content: "";
  display: block;
  width: 100%;
  margin-top: 44px !important;
  height: 7px;
  background-color: #6c872e;
}

.button-item {
  padding-top: 60px;
  margin-right: 25px;
}

.button-item :hover {
  background-color: #078c;
}

.drop-link {
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  padding-left: 20px;
  background-color: white;
  font-size: 15px;
}

.drop-link:hover {
  background-color: white;
  cursor: pointer;
  color: #6c872e;
  transition: color 0.3s ease;
}

.dropdownButton {
  position: absolute;
  top: 6.2rem;
  right: 1.5rem;
  width: 200px;
  border-radius: 15px;
  background-color: rgb(255, 255, 255);
  box-shadow: 2px #6c872e;
  border: solid 2px #e2eacd;
  z-index: 1000;
}

.dropdownButton.show {
  max-height: 500px;
}

.dropdownButton:hover {
  background-color: rgb(255, 255, 255);
  box-shadow: 2px #6c872e;
  border: solid 2px #e2eacd;
}

.dropdownButton::before {
  content: "";
  position: absolute;
  top: -0.7rem;
  right: 1rem;
  width: 20px;
  height: 20px;
  height: 25px;
  transform: rotate(45deg);
  background-color: rgb(255, 255, 255);
  border-left: 1px solid #e2eacd;
  border-top: 1px solid #e2eacd;
  z-index: 1000;
}
