.do-item {
  display: flex;
  padding: 8px;
}

.do-icon {
  width: 70px;
  margin-left: -5px;
}

.do-title {
  font-size: 29px;
  font-weight: 900;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin-top: 20px;
  margin-right: 300px;
  color: rgb(6, 5, 5);
}
