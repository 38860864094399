.visio-container {
  background-color: white;
  height: 100vh;
  width: 60%;
  overflow-y: hidden;
  box-sizing: border-box;
}

.header-visio {
  background-color: #024631;
  height: 100px;
  border-bottom-right-radius: 10px;
}

.logo-visio {
  padding: 10px;
  width: 250px;
  height: 100px;
}

#controls {
  display: flex;
  gap: 10px;
  position: fixed;
  bottom: 20px;
  left: 28%;
  transform: translateX(-50%);
}

.control-container {
  background-color: #93b838;
  padding: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.control-container img {
  height: 30px;
  width: 30px;
}

#leave-btn {
  background-color: rgb(255, 80, 80, 1);
}

.both {
  display: flex;
  background-color: white;
}

.observation-ordonnance {
  background-color: white;
  width: 40%;
}
